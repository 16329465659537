
.easy_light[data-v-deb1d5d8] {
  fill: #6DFD64;
  stop-color: #6DFD64;
}
.hard_light[data-v-deb1d5d8] {
  fill: #00DCD4;
  stop-color: #00DCD4;
}
.master_light[data-v-deb1d5d8] {
  fill: #FF37D9;
  stop-color: #FF37D9;
}
.inferno_light[data-v-deb1d5d8] {
  fill: #3a3a3a;
  stop-color: #3a3a3a;
}
.touhou_light[data-v-deb1d5d8] {
  fill: #dfdfdf;
  stop-color: #dfdfdf;
}
.easy_dark[data-v-deb1d5d8] {
  fill: #1CB161;
  stop-color: #1CB161;
}
.hard_dark[data-v-deb1d5d8] {
  fill: #6285A6;
  stop-color: #6285A6;
}
.master_dark[data-v-deb1d5d8] {
  fill: #B100E5;
  stop-color: #B100E5;
}
.inferno_dark[data-v-deb1d5d8] {
  fill: #000000;
  stop-color: #000000;
}
.touhou_dark[data-v-deb1d5d8] {
  fill: #7e7e7e;
  stop-color: #7e7e7e;
}
.white[data-v-deb1d5d8] {
  fill: white;
  stop-color: white;
}


.def[data-v-11f4ff3a] {
  position: absolute;
  top: -1000px;
}
.mdmcPink[data-v-11f4ff3a]:hover {
  color: #ff55c3;
}
.mdmcPink.is-active[data-v-11f4ff3a] {
  color: #ff55c3;
}
.blackWhite[data-v-11f4ff3a] {
  filter: grayscale(100%);
}
.anniversary[data-v-11f4ff3a] {
  transition: font-size 0.8s ease;
}
.anniversary-rainbow[data-v-11f4ff3a] {
  background: linear-gradient(to right, #ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #8f00ff, #ff0000, #ff7f00, #ffff00, #00ff00, #0000ff, #4b0082, #8f00ff, #ff0000);
  background-clip: text;
  -webkit-background-clip: text;
  animation: rainbow_animation-11f4ff3a 4s linear infinite;
  background-size: 200% 100%;
  transition: color 0.8s ease;
}
@keyframes rainbow_animation-11f4ff3a {
0% {
    background-position: 0% 0;
}
100% {
    background-position: 100% 0;
}
}
.anniversary[data-v-11f4ff3a]:hover {
  font-size: 50px;
}
.anniversary-rainbow[data-v-11f4ff3a]:hover {
  color: transparent;
}


@font-face {
  font-family: "Impact-face";
  src: url(/static/fonts/Impact.hash.3eb51b5.ttf) format("truetype");
}
.only[data-v-2da7981a] {
  opacity: 0;
}
.router-link-active>.node>.only[data-v-2da7981a] {
  opacity: 1;
}
.capsule[data-v-2da7981a] {
  display: inline-block;
  background-color: #27184D;
  text-align: center;
  padding: 16px;
  margin: 16px;
  width: auto;
  height: 80px;
  border-radius: 48px;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}
.node[data-v-2da7981a] {
  display: block;
  width: 48px;
  height: 48px;
  float: left;
}
.empty[data-v-2da7981a] {
  background-color: #1E0937;
  border-radius: 50%;
}
.node .dot[data-v-2da7981a] {
  width: 12px;
  height: 12px;
  margin: 18px;
  border-radius: 50%;
  background-color: #331754;
}
.text[data-v-2da7981a] {
  z-index: 2;
  text-align: center;
  font-family: Impact, "Impact-face";
  font-size: 24px;
  dominant-baseline: central;
  text-anchor: middle;
}
.outline_easy[data-v-2da7981a] {
  text-shadow:
    #6DFD64 -2px -2px,
    #6DFD64 0 -2px,
    #6DFD64 2px -2px,
    #6DFD64 2px 0,
    #6DFD64 2px 2px,
    #6DFD64 0 2px,
    #6DFD64 -2px 2px,
    #6DFD64 -2px 0
}
.outline_hard[data-v-2da7981a] {
  text-shadow:
    #00DCD4 -2px -2px,
    #00DCD4 0 -2px,
    #00DCD4 2px -2px,
    #00DCD4 2px 0,
    #00DCD4 2px 2px,
    #00DCD4 0 2px,
    #00DCD4 -2px 2px,
    #00DCD4 -2px 0
}
.outline_master[data-v-2da7981a] {
  text-shadow:
    #FF37D9 -2px -2px,
    #FF37D9 0 -2px,
    #FF37D9 2px -2px,
    #FF37D9 2px 0,
    #FF37D9 2px 2px,
    #FF37D9 0 2px,
    #FF37D9 -2px 2px,
    #FF37D9 -2px 0;
}
.outline_inferno[data-v-2da7981a] {
  text-shadow:
    #3a3a3a -2px -2px,
    #3a3a3a 0 -2px,
    #3a3a3a 2px -2px,
    #3a3a3a 2px 0,
    #3a3a3a 2px 2px,
    #3a3a3a 0 2px,
    #3a3a3a -2px 2px,
    #3a3a3a -2px 0;
}
.outline_touhou[data-v-2da7981a] {
  text-shadow:
    #dfdfdf -2px -2px,
    #dfdfdf 0 -2px,
    #dfdfdf 2px -2px,
    #dfdfdf 2px 0,
    #dfdfdf 2px 2px,
    #dfdfdf 0 2px,
    #dfdfdf -2px 2px,
    #dfdfdf -2px 0;
}
.easy_text[data-v-2da7981a] {
  fill: #346460;
}
.hard_text[data-v-2da7981a] {
  fill: #41548D;
}
.master_text[data-v-2da7981a] {
  fill: #5B1787;
}
.inferno_text[data-v-2da7981a] {
  fill: #7e7e7e;
}
.touhou_text[data-v-2da7981a] {
  fill: black;
}
.capsule .padding[data-v-2da7981a] {
  width: 16px;
  height: 100%;
  display: inline-block;
  float: left;
  background-color: transparent;
}


.musicContainer[data-v-e8b08380] {
  position: relative;
}
.abs[data-v-e8b08380] {
  position: absolute;
  width: 100%;
}


.overflowhide[data-v-fe09583e]::-webkit-scrollbar {
  display: none;
}


.tag-container[data-v-6bf5cbf8] {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.tag-btn[data-v-6bf5cbf8] {
  background-color: rgb(113, 189, 244);
  border-radius: 12px;
  height: 67px;
  width: 67px;
  overflow: hidden;
  margin: 4px;
  transition: width 0.3s;
}
.tag-content-container[data-v-6bf5cbf8] {
  display: flex;
  height: 67px;
  width: 200px;
}
.tag-all[data-v-6bf5cbf8] {
  background-color: rgb(215, 74, 171);
}
.tag-img[data-v-6bf5cbf8] {
  height: 67px;
  width: 67px;
}
.tag-text[data-v-6bf5cbf8] {
  font-weight: bold;
  margin: auto;
  color: black;
  opacity: 0.4;
  font-size: 22px;
  max-width: 133px;
  line-height: 20px;
}
.tag-btn-selecte[data-v-6bf5cbf8] {
  width: 200px;
}


.album-title[data-v-d2209f20] {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
}


.svgWWW[data-v-06a71174] {
  fill: currentColor;
}


.th-left[data-v-c59648b2] {
  text-align: left !important;
}
.nowarp[data-v-c59648b2] {
  white-space: nowrap;
}
.ellipsis[data-v-c59648b2] {
  text-overflow: ellipsis;
  max-width: 11em;
  overflow: hidden;
}
.search[data-v-c59648b2] {
  width: 30%;
  min-width: 70px;
  font-size: 0.75em;
  margin-left: 5%;
}
.no-padding[data-v-c59648b2] {
  padding: 0;
}


.clickable[data-v-155b52ff] {
  cursor: pointer;
}
.level-item[data-v-155b52ff] {
  flex: 1;
}
.level-item[data-v-155b52ff]:nth-child(2) {
  flex: 2;
}


.canvas[data-v-1d16f74a] {
  height: 320px;
  margin: auto;
}


form[data-v-74554bf5] {
  margin-bottom: 23.3px;
}
.black[data-v-74554bf5] {
  color: #4a4a4a;
}


.break[data-v-14600f0b] {
  word-wrap: break-word;
}
.log[data-v-14600f0b] {
  height: 480px;
  overflow-y: auto;
}


.level-item[data-v-58acf496] {
  flex: 1;
}
.level-item[data-v-58acf496]:nth-child(2) {
  flex: 2;
}


.diff-relative[data-v-782f392a] {
  position: relative;
}


.mdmcPink[data-v-2a6b05a4] {
  color: #ff55c3;
}

